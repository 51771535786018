export const GOOGLE_CLIENT_ID =
  "704609242086-dabkajm8tj8crsgcs2lvecej4qaimi02.apps.googleusercontent.com";

export const LANGUAGE_OPTIONS = {
  Estonian: "estonian",
  Czech: "czech",
  Catalan: "catalan",
  Afrikaans: "afrikaans",
  Thai: "thai",
  Russian: "russian",
  Farsi: "farsi",
  Finnish: "finnish",
  Macedonian: "macedonian",
  Esperanto: "esperanto",
  Spanish: "spanish",
  Vietnamese: "vietnamese",
  Hungarian: "hungarian",
  Hindi: "hindi",
  Turkish: "turkish",
  Greek: "greek",
  Swedish: "swedish",
  Japanese: "japanese",
  German: "german",
  Latvian: "latvian",
  Ukrainian: "ukrainian",
  Slovak: "slovak",
  Serbian: "serbian",
  Italian: "italian",
  Danish: "danish",
  Bulgarian: "bulgarian",
  Korean: "korean",
  Chinese: "chinese",
  Polish: "polish",
  Languages: "languages",
  French: "french",
  Kazakh: "kazakh",
  Norwegian: "norwegian",
  Romanian: "romanian",
  Portuguese: "portuguese",
  Indonesian: "indonesian",
  Slovenian: "slovenian",
  Arabic: "arabic",
  Lithuanian: "lithuanian",
  Dutch: "dutch",
  Bengali: "bengali",
  Hebrew: "hebrew",
  English: "english",
  Albanian: "albanian",
};

interface CodesMap {
  [x: string]: string;
}

export const ISO_LANGUAGE_CODES: CodesMap = {
  estonian: "et",
  czech: "cs",
  catalan: "ca",
  afrikaans: "af",
  thai: "th",
  russian: "ru",
  finnish: "fi",
  macedonian: "mk",
  esperanto: "eo",
  spanish: "es",
  vietnamese: "vi",
  hungarian: "hu",
  hindi: "hi",
  turkish: "tr",
  swedish: "sv",
  japanese: "ja",
  german: "de",
  latvian: "lv",
  ukrainian: "uk",
  slovak: "sk",
  serbian: "sr",
  italian: "it",
  danish: "da",
  bulgarian: "bg",
  korean: "ko",
  chinese: "zh",
  polish: "pl",
  french: "fr",
  kazakh: "kk",
  norwegian: "no",
  romanian: "ro",
  portuguese: "pt",
  indonesian: "id",
  arabic: "ar",
  lithuanian: "lt",
  dutch: "nl",
  english: "en",
  albanian: "sq",
};
